import React, { useState } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import { Button, Loader, View, withAuthenticator } from "@aws-amplify/ui-react";
import { Notes } from "./Notes/Notes.js";
import {User} from "./User/User.tsx";

const App = ({ signOut }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userError, setUserError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  if (user == null && loading) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setLoading(false);
        Auth.userAttributes(user).then((attributes) => {
          const claim = attributes.find(
            (attribute) => attribute.Name === "custom:claim"
          );
          setIsAdmin(claim?.Value.match("SUPER_ADMIN"));
        });
      })
      .catch((err) => {
        setUserError(err);
        setUser(null);
        setLoading(false);
      });
    return <Loader />;
  }

  if (userError) {
    return (
      <div>
        <h1>Error</h1>
        <p>{userError.message}</p>
      </div>
    );
  }

  return (
    <View className="App">
      <User user={user} isAdmin={isAdmin} />
      <Notes />
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(App);
